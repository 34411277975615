import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import React, { useState, useEffect, Suspense } from "react";

import logo from "./logo.svg";
import "./App.css";
// import Verification from './views/Login/Verification';
import axios from "axios";
import { BASE_URL } from "./config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let Loading = React.lazy(() => import("./component/Loading"));
let Header = React.lazy(() => import("./component/Header"));
let Footer = React.lazy(() => import("./component/Footer"));
let Home = React.lazy(() => import("./views/Home/Home"));
let AboutUS = React.lazy(() => import("./views/AboutUS/AboutUs"));
let WhyChooseUs = React.lazy(() => import("./views/WhyChooseUs/WhyChooseUs")); 
let Services = React.lazy(() => import("./views/Services/Services")); 
let ContactUs = React.lazy(() => import("./views/ContactUs/ContactUs"));
// let Login=React.lazy(() => import("./views/Login/Login"))
// let Sign_Up=React.lazy(() => import("./views/SignUp/Sign_Up_step1"))
let Request=React.lazy(() => import("./views/Request/Request"))

function App() {
  const [siteData, setSiteData] = useState("");

  const fetchSiteSetting = async () => {
    try {
      setSiteData({});
      let sitefetchData = await axios.get(`${BASE_URL}/settings`);
      console.log("site data==<", sitefetchData);
      if (sitefetchData.data.responseCode === 1) {
        setSiteData(sitefetchData.data.responseData);
      }

      // console.log("site data==<", siteData);
    } catch (error) {
      // console.log("site data==<", error);
    }
  };

  useEffect(() => {
    // fetchSiteSetting();
  }, []);

  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Suspense fallback={<Loading />}>
          <Header settingData={siteData} />
          <Routes>
            <Route path="/" element={<Navigate to="/index" />} />
            <Route
              exact
              path="/index"
              element={
                <React.Suspense fallback={<Loading />}>
                  <Home />
                </React.Suspense>
              }
            />
            {/* <Route exact path='/about-us' element={<React.Suspense fallback={<>...</>}><About /></React.Suspense>} /> */}
            <Route exact path="/about-us" element={<AboutUS />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route exact path='/demo' element={<Request/>} />
            <Route exact path="/why-choose-us" element={<WhyChooseUs />} />
            <Route exact path="/service" element={<Services />} />

            {/* <Route exact path='/login' element={<React.Suspense fallback={<>...</>}><Login /></React.Suspense>} />
          <Route exact path='/login/verification' element={<React.Suspense fallback={<>...</>}><Verification /></React.Suspense>} />
          <Route exact path='/Sign_Up' element={<React.Suspense fallback={<>...</>}><Sign_Up /></React.Suspense>} /> */}
            {/* <Route exact path='/Sign_Up/next' element={<React.Suspense fallback={<>...</>}><Step_address /></React.Suspense>} />
          <Route exact path='/Sign_Up/next2' element={<React.Suspense fallback={<>...</>}><Step_choose /></React.Suspense>} /> */}

            {/*  <Route exact path='/about-us' element={<React.Suspense fallback={<>...</>}><About /></React.Suspense>} />

          <Route exact path='/how-it-works' element={<React.Suspense fallback={<>...</>}><HowitWorks /></React.Suspense>} />

          <Route exact path='/privacy' element={<React.Suspense fallback={<>...</>}><PrivacyPolicy /></React.Suspense>} />
          
          <Route exact path='/terms-condition' element={<React.Suspense fallback={<>...</>}><TermsAndConditions /></React.Suspense>} />
          <Route exact path='/register' element={<React.Suspense fallback={<>...</>}><Register /></React.Suspense>} />
          <Route exact path='/login' element={<React.Suspense fallback={<>...</>}><Login /></React.Suspense>} />
          <Route exact path='/dashboard' element={<React.Suspense fallback={<>...</>}><Dashboard /></React.Suspense>} /> */}

            <Route path="/*" element={<Navigate to="/index" />} />
          </Routes>
          <Footer settingData={siteData} />
        </Suspense>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
