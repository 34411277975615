import { SET_SITE_DATA,SET_PAGE,SET_USER_DATA} from "./Action";

const initialState = {
   siteData:{},
   userData:{},
   page:""
  };

  const reducer=(state=initialState,{type,payload})=>{
    switch (type){
            case SET_SITE_DATA:
                return{ ...state, siteData: payload };
            case SET_PAGE:
                return{ ...state, page: payload };
            case SET_USER_DATA:
                return{ ...state, userData: payload };
        default:
            return state;
    }
  }


  export default reducer